<template>
  <div>
    <a-spin size="large" :spinning="spinning">
      <a-card>
        <a-form class="formModel" layout="inline">
          <a-button type="link" @click="back"> 返回 </a-button>
          <a-form-item label="姓名：">
            <a-input style="width: 200px;" v-model="search.staffName" placeholder="请输入姓名" />
          </a-form-item>

          <a-form-item label="手机号：">
            <a-input style="width: 200px;" v-model="search.staffPhone" placeholder="请输入手机号" />
          </a-form-item>
          <a-form-item label="角色：">
            <a-select style="width: 200px;" v-model="search.staffRoleId" :placeholder="'请选择'">
              <a-select-option v-for="(item, index) in allRole" :key="index" :value="item.role_id">
                {{ item.role_name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-button class="m-20" type="primary" style="margin-right: 10px;" @click="searchStaffList">
              <a-icon type="search" />
              查询
            </a-button>
            <a-button class="m-10" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
        <a-table style="margin-top:20px;" :rowKey="(record, index) => record.menuId" :columns="table.columns" :data-source="table.data"
          :pagination="pagination" @change="pageChange">
          <span slot="action" slot-scope="text, record">
            <a-button type="link" @click="look(text)" style="margin-right: 10px;">查看</a-button>
            <a-button type="link" @click="edit(text)">编辑</a-button>
            <deletebut tipsTitle="确认删除该用户信息?" tipsContent="删除后将从该企业移出,请谨慎操作!" @deleteBut="remove" :record="record">
            </deletebut>
          </span>
        </a-table>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import * as api from '@/api/enterprise/enterprise.js'
import deletebut from '@/components/delete/index'
import { pagerules } from '@/utils/utils.js'
export default {
  name: 'userlook',
  components: {
    deletebut
  },
  data() {
    return {
      spinning: false,
      // 所有可选角色
      allRole: [],
      search: {
        // 员工姓名
        staffName: '',
        // 员工手机号
        staffPhone: '',
        // 员工角色 id
        staffRoleId: '',
        // 企业id
        id: ''
      },
      pagination: {
        pageSizeOptions: ['5', '10', '20'],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total, range) => {
          return `共${total}条`
        }
      },
      table: {
        columns: [
          {
            title: '账号',
            dataIndex: 'phone_number',
            key: 'staff_id'
          },
          {
            title: '真实姓名',
            dataIndex: 'staff_name',
            key: 'staff_name'
          },
          {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number'
          },
          {
            title: '部门',
            dataIndex: 'depart_name',
            key: 'depart_name'
          },
          {
            title: '角色',
            dataIndex: 'role_name',
            key: 'role_name'
          },
          {
            title: '操作',
            key: 'action',
            className: 'action-ql-list',
            scopedSlots: { customRender: 'action' }
          }
        ],
        // 列表数据
        data: []
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 查询
    searchStaffList() {
      this.pagination.current = 1
      this.getStaffList()
    },
    // 角色
    remove(data) {
      const that = this
      that.deleteStaff(data)
    },
    pageChange(pageOption) {
      this.pagination.current = pageOption.current
      this.pagination.pageSize = pageOption.pageSize
      this.getStaffList()
    },
    // 重置
    resetSearch() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.search = {
        staffName: '',
        staffPhone: '',
        staffRoleId: '',
        id: this.$route.query.id
      }
      this.getStaffList()
    },

    /** 网络请求 */
    // 获取员工列表
    async getStaffList() {
      try {
        this.spinning = true
        const result = await api.getCurrentEnterStaffList(
          this.search,
          this.pagination.current - 1,
          this.pagination.pageSize
        )
        
        this.spinning = false
        if (result.data) {
          this.table.data = result.data.items
          this.pagination.total = result.data.total
        }
      } catch (error) {
        console.log(error,'====================>>>');
        this.spinning = false
      }
    },
    // 移除员工
    async deleteStaff(data) {
      const deleteData = {
        ent_id: data.enterprise_id,
        id: data.staff_id
      }
      try {
        this.spinning = true
        const result = await api.deleteStaff(deleteData)
        this.spinning = false
        if (result.data === '删除成功') {
          this.pagination.current = pagerules(
            this.table.data.length,
            1,
            this.pagination.current
          )
          this.$message.success('删除成功')
          this.getStaffList()
        } else {
          this.$message.error('删除失败')
        }
      } catch (error) {
        this.spinning = false
        this.$message.error('删除失败')
      }
    },
    look(item) {
      this.$router.push({
        path: '/enterprise/userList/view',
        query: {
          staffId: item.staff_id,
          enterprise_id: item.enterprise_id,
          // 告诉目标 路由是从哪个页面跳过来的
          from: 'look'
        }
      })
    },
    // 编辑
    edit(item) {
      this.$router.push({
        path: '/enterprise/userList/addStaff',
        query: {
          id: item.staff_id,
          enterprise_id: item.enterprise_id,
          isEdit: true,
          // 告诉目标 路由是从哪个页面跳过来的
          from: 'look'
        }
      })
    },
    // 获取角色列表
    async getRoleList() {
      let tempArr = [{ role_id: '', role_name: '全部' }]
      try {
        const result = await api.getRoleList()

        result.data.data.forEach(item => {
          tempArr.push({
            role_id: item.roleId,
            role_name: item.roleName
          })
        })
        this.allRole = tempArr
      } catch (error) { }
    }
  },
  created() {
    this.search.id = this.$route.query.id
    this.getStaffList()
    this.getRoleList()
  }
}
</script>
